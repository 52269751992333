(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name printCard.controller:PrintCardCtrl
   *
   * @description
   *
   */
  angular
      .module('neo.home.entitats.entitat.printCards')
      .controller('PrintCardsCtrl', PrintCardsCtrl);

  function PrintCardsCtrl(athletesCards, townHall, entitat, categories, sports, licencesTypes, currentSeason, Foto) {
    var vm = this;
    vm.ctrlName = 'PrintCardsCtrl';

    vm.cards = [];

    var SCHOOL_GAMES_ID = 1;

    vm.licensesByTeam = {};
    angular.forEach(athletesCards, function (license) {
      var card = license.license;
      if (card.team.programmeId === SCHOOL_GAMES_ID) {
        var teamId = card.team.id;
        if (!(teamId in vm.licensesByTeam)) {
          vm.licensesByTeam[teamId] = {
            name: card.team.name,
            licenses: [],
            selected: true
          }
        }

        vm.licensesByTeam[teamId].licenses.push({
          selected: true,
          name: card.person.name,
          surname: card.person.surname1 +' '+ (card.person.surname2 !== null ? card.person.surname2 : ''),
          birthday: moment(card.person.bornDate).format('L'),
          entity: entitat.name,
          team: card.team.name,
          teamCategory: findItemById(categories, card.team.categoryId).name,
          sport: findItemById(sports, card.team.sportId).name,
          personCategory: card.category.name,
          licenceType: findItemById(licencesTypes, card.licenceTypeId).name,
          townHall: townHall.name,
          licenseCode: license.licenseCode,
          season: {
            initDate: moment(currentSeason.iniDate).format('YYYY'),
            endDate: moment(currentSeason.endDate).format('YYYY')
          },id:card.person.id,foto:""
        });
      }
    });

    vm.selectAllAssociatedLicenses = function (teamIndex) {
      var isTeamSelected = vm.licensesByTeam[teamIndex].selected === true;
      angular.forEach(vm.licensesByTeam[teamIndex].licenses, function (license) {
        license.selected = isTeamSelected;
      });
    };

    vm.generateReport = function () {
      _.remove(vm.cards);
    
      angular.forEach(vm.licensesByTeam, function (team) {
        angular.forEach(team.licenses, function (license) {
          if (license.selected) {
            license.foto=Foto.query({personId:license.id});
            vm.cards.push(license);
            
      
          }
        });
      });

      var template = document.getElementById('cardsPrintPreview').innerHTML;
      var printWin = window.open('', '',
          'left=0, top=0, width=' + screen.availWidth + ', height=' + screen.availHeight
          + ', toolbar=0, scrollbars=0, status=0');
      
      printWin.document.write(template);
      printWin.document.close();
      //printWin.onload = function () {
      setTimeout(function () {
        printWin.focus();
        printWin.print();
        printWin.close();
      }, 500);
  }
    

    function findItemById(collection, itemId) {
      return _.find(collection, function (i) {
        return i.id === itemId;
      });
    }
  }
}());
